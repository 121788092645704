import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import CartItem from "./CartItem";
import { selectCart } from "../../redux/cart/selectors";
import { selectCurrency } from "../../redux/currency/selectors";
import { cartClear } from "../../redux/cart/asyncActions";
import { Link } from "react-router-dom";

import { Row, Col, Button, Result, Modal } from "antd";
import { CloseOutlined, QuestionCircleOutlined } from "@ant-design/icons";

import s from "./Cart.module.scss";

const CartPage: React.FC = () => {
  const { totalPrice, items } = useAppSelector(selectCart);
  const currency = useAppSelector(selectCurrency);
  const dispatch = useAppDispatch();
  const totalCount = items.reduce((sum: number, item: any) => sum + item.cnt, 0);
  const [modalOpen, setModalOpen] = useState(false);

  const onAcceptDeleteProduct = () => {
    dispatch(cartClear());
    setModalOpen(false);
  };

  return (
    <>
      <Row justify="center">
        <Col xs={{ span: 23 }} sm={{ span: 23 }} md={{ span: 18 }} lg={{ span: 18 }} xl={{ span: 14 }}>
          <Row align="middle" justify="space-between">
            <Col className={s.cart_topic}>
              <b>Корзина</b>
            </Col>
            {!(totalCount === 0) && (
              <Col>
                <Button onClick={() => setModalOpen(true)}>
                  Очистить
                  <CloseOutlined />
                </Button>
              </Col>
            )}
          </Row>

          <div>
            {items.map((item: any) => (
              <CartItem key={item.nomenclatureid} {...item} />
            ))}
          </div>
          {!(items.length > 0) && (
            <Result
              status="warning"
              title="В вашей корзине пусто,вернитесь в магазин чтобы добавить товар."
              extra={
                <Link to="/shop">
                  <Button type="primary" key="console">
                    Вернуться в магазин
                  </Button>
                </Link>
              }
            />
          )}

          {items.length > 0 && (
            <>
              <div>
                <p>
                  Всего позиций: <b>{totalCount}</b>
                </p>
              </div>
              <div>
                <p>
                  Сумма заказа:{" "}
                  <b>
                    {totalPrice.toLocaleString()} {currency.nameshort}
                  </b>
                </p>
              </div>

              <Link to="/order">
                <Button block type="primary" size="large" style={{ marginBottom: "30px" }}>
                  Сформировать заказ
                </Button>
              </Link>
            </>
          )}
        </Col>
      </Row>
      <Modal
        title={
          <div style={{ display: "flex" }}>
            <QuestionCircleOutlined style={{ fontSize: "40px", color: "red" }} />
            <h3 style={{ marginLeft: "30px" }}>Удалить все позиции корзины?</h3>
          </div>
        }
        centered
        open={modalOpen}
        onOk={onAcceptDeleteProduct}
        onCancel={() => setModalOpen(false)}
        okText="Удалить"
        cancelText="Отмена"
      />
    </>
  );
};

export default CartPage;
