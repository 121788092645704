export type Nomenclature = {
    NomenclatureID: string;
    Name: string;
    NameFull: string;
    Code: string;
    Description: string;
    price: number;
    imageUrl: string[];
    rating: number;
  };

  export type NomenclatureList = {
    totalcount: number;
    items: Nomenclature[];    
  }

  
  export enum NomenclatureStatus {
    LOADING = 'loading',
    SUCCESS = 'completed',
    ERROR = 'error',
  }
  
  export type NomenclatureSearchParams = {
    sortBy: string;
    order: string;
    category: string;
    search: string;
    currentPage: string;
    limit: string;
  };
  
  export interface NomenclatureSliceState {
    items: Nomenclature[];
    totalcount: number;
    status: NomenclatureStatus;
  }
  