import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectUser } from "../../redux/user/selectors";
import AuthService from "../../services/AuthService";
import { setUser } from "../../redux/user/slice";
import { cartFetch } from "../../redux/cart/asyncActions";
import config from "../../config";

import s from "./LoginModal.module.scss";
import { Button, Form, Input, Modal } from "antd";
import google from "../../assets/img/svg/google.svg";
import { Link } from "react-router-dom";

type LoginModalProps = {
  handleClose: () => void;
  registrationClick: () => void;
};
export const LoginModal: React.FC<LoginModalProps> = (props) => {
  const [login, setLogin] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectUser);
  const licenseAndUpdateSalt = "no{sdfgdfsd][U8kdfkO)*hj4,dxd";

  const [form] = Form.useForm();
  const handleClose = () => props.handleClose();
  const regClick = () => {
    props.registrationClick();
  };
  const onLoginClick = () => {
    AuthService.login(login, SHA256(password + licenseAndUpdateSalt))
      .then((res) => {
        localStorage.setItem("token", res.data.accessToken);
        dispatch(setUser(res.data));
        dispatch(cartFetch());
        handleClose();
      })
      .catch((e) => {
        if (e.response) {
          console.log(e.response.data);
          alert(e.response.data.message);
        } else {
          console.log("Error", e.message);
          alert(e.message);
        }
      });
  };

  const onLoginGoogleClick = (e: any) => {
    window.open(`${config.API_URL}/auth/google/shop/${config.SHOP}`, "_self");
  };

  if (user) {
    return <></>;
  }

  /**
   * Secure Hash Algorithm (SHA256)
   * http://www.webtoolkit.info/
   * Original code by Angel Marin, Paul Johnston
   **/

  function SHA256(s: string) {
    var chrsz = 8;
    var hexcase = 0;

    function safe_add(x: number, y: number) {
      var lsw = (x & 0xffff) + (y & 0xffff);
      var msw = (x >> 16) + (y >> 16) + (lsw >> 16);
      return (msw << 16) | (lsw & 0xffff);
    }

    function S(X: number, n: number) {
      return (X >>> n) | (X << (32 - n));
    }
    function R(X: number, n: number) {
      return X >>> n;
    }
    function Ch(x: number, y: number, z: number) {
      return (x & y) ^ (~x & z);
    }
    function Maj(x: number, y: number, z: number) {
      return (x & y) ^ (x & z) ^ (y & z);
    }
    function Sigma0256(x: number) {
      return S(x, 2) ^ S(x, 13) ^ S(x, 22);
    }
    function Sigma1256(x: number) {
      return S(x, 6) ^ S(x, 11) ^ S(x, 25);
    }
    function Gamma0256(x: number) {
      return S(x, 7) ^ S(x, 18) ^ R(x, 3);
    }
    function Gamma1256(x: number) {
      return S(x, 17) ^ S(x, 19) ^ R(x, 10);
    }

    function core_sha256(m: Array<number>, l: number) {
      var K = new Array(
        0x428a2f98,
        0x71374491,
        0xb5c0fbcf,
        0xe9b5dba5,
        0x3956c25b,
        0x59f111f1,
        0x923f82a4,
        0xab1c5ed5,
        0xd807aa98,
        0x12835b01,
        0x243185be,
        0x550c7dc3,
        0x72be5d74,
        0x80deb1fe,
        0x9bdc06a7,
        0xc19bf174,
        0xe49b69c1,
        0xefbe4786,
        0xfc19dc6,
        0x240ca1cc,
        0x2de92c6f,
        0x4a7484aa,
        0x5cb0a9dc,
        0x76f988da,
        0x983e5152,
        0xa831c66d,
        0xb00327c8,
        0xbf597fc7,
        0xc6e00bf3,
        0xd5a79147,
        0x6ca6351,
        0x14292967,
        0x27b70a85,
        0x2e1b2138,
        0x4d2c6dfc,
        0x53380d13,
        0x650a7354,
        0x766a0abb,
        0x81c2c92e,
        0x92722c85,
        0xa2bfe8a1,
        0xa81a664b,
        0xc24b8b70,
        0xc76c51a3,
        0xd192e819,
        0xd6990624,
        0xf40e3585,
        0x106aa070,
        0x19a4c116,
        0x1e376c08,
        0x2748774c,
        0x34b0bcb5,
        0x391c0cb3,
        0x4ed8aa4a,
        0x5b9cca4f,
        0x682e6ff3,
        0x748f82ee,
        0x78a5636f,
        0x84c87814,
        0x8cc70208,
        0x90befffa,
        0xa4506ceb,
        0xbef9a3f7,
        0xc67178f2
      );
      var HASH = new Array(0x6a09e667, 0xbb67ae85, 0x3c6ef372, 0xa54ff53a, 0x510e527f, 0x9b05688c, 0x1f83d9ab, 0x5be0cd19);
      var W = new Array(64);
      var a, b, c, d, e, f, g, h, i, j;
      var T1, T2;

      m[l >> 5] |= 0x80 << (24 - (l % 32));
      m[(((l + 64) >> 9) << 4) + 15] = l;

      for (let i = 0; i < m.length; i += 16) {
        a = HASH[0];
        b = HASH[1];
        c = HASH[2];
        d = HASH[3];
        e = HASH[4];
        f = HASH[5];
        g = HASH[6];
        h = HASH[7];

        for (let j = 0; j < 64; j++) {
          if (j < 16) W[j] = m[j + i];
          else W[j] = safe_add(safe_add(safe_add(Gamma1256(W[j - 2]), W[j - 7]), Gamma0256(W[j - 15])), W[j - 16]);

          T1 = safe_add(safe_add(safe_add(safe_add(h, Sigma1256(e)), Ch(e, f, g)), K[j]), W[j]);
          T2 = safe_add(Sigma0256(a), Maj(a, b, c));

          h = g;
          g = f;
          f = e;
          e = safe_add(d, T1);
          d = c;
          c = b;
          b = a;
          a = safe_add(T1, T2);
        }

        HASH[0] = safe_add(a, HASH[0]);
        HASH[1] = safe_add(b, HASH[1]);
        HASH[2] = safe_add(c, HASH[2]);
        HASH[3] = safe_add(d, HASH[3]);
        HASH[4] = safe_add(e, HASH[4]);
        HASH[5] = safe_add(f, HASH[5]);
        HASH[6] = safe_add(g, HASH[6]);
        HASH[7] = safe_add(h, HASH[7]);
      }
      return HASH;
    }

    function str2binb(str: String) {
      var bin = Array<number>();
      var mask = (1 << chrsz) - 1;
      for (var i = 0; i < str.length * chrsz; i += chrsz) {
        bin[i >> 5] |= (str.charCodeAt(i / chrsz) & mask) << (24 - (i % 32));
      }
      return bin;
    }

    function Utf8Encode(str: String) {
      str = str.replace(/\r\n/g, "\n");
      var utftext = "";

      for (var n = 0; n < str.length; n++) {
        var c = str.charCodeAt(n);

        if (c < 128) {
          utftext += String.fromCharCode(c);
        } else if (c > 127 && c < 2048) {
          utftext += String.fromCharCode((c >> 6) | 192);
          utftext += String.fromCharCode((c & 63) | 128);
        } else {
          utftext += String.fromCharCode((c >> 12) | 224);
          utftext += String.fromCharCode(((c >> 6) & 63) | 128);
          utftext += String.fromCharCode((c & 63) | 128);
        }
      }
      return utftext;
    }

    function binb2hex(binarray: Array<number>) {
      var hex_tab = hexcase ? "0123456789ABCDEF" : "0123456789abcdef";
      var str = "";
      for (var i = 0; i < binarray.length; i++) {
        var str1 = "";
        for (var j = 0; j < 4; j++) {
          var k = i * 4 + j;
          str1 = hex_tab.charAt((binarray[k >> 2] >> ((3 - (k % 4)) * 8 + 4)) & 0xf) + hex_tab.charAt((binarray[k >> 2] >> ((3 - (k % 4)) * 8)) & 0xf) + str1;
        }
        str += str1;
      }
      return str.toUpperCase();
    }

    s = Utf8Encode(s);
    return binb2hex(core_sha256(str2binb(s), s.length * chrsz));
  }
  return (
    <Modal open={true} title="Вход" footer={null} onCancel={handleClose}>
      <Form className={s.logform} form={form} name="basic" labelCol={{ span: 5 }} wrapperCol={{ span: 20 }} initialValues={{ remember: true }} autoComplete="off">
        <Form.Item label="Email" name="email" rules={[{ required: true, message: "Please input your username!" }]}>
          <Input value={login} onChange={(e) => setLogin(e.target.value)} />
        </Form.Item>
        <Form.Item label="Пароль" name="password" rules={[{ required: true, message: "Please input your password!" }]}>
          <Input.Password value={password} onChange={(e) => setPassword(e.target.value)} />
        </Form.Item>

        <Button
          type="primary"
          size="large"
          htmlType="submit"
          className={s.login_btn}
          block
          onClick={onLoginClick}
          disabled={!form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length}
        >
          Войти
        </Button>
        <Link to={""} onClick={regClick} style={{ textAlign: "center", color: "#1e266d", textDecoration: "underline" }}>
          <p>Зарегистрироваться</p>
        </Link>

        <h2 className={s.other_entries}>Войти через сторонние сайты:</h2>

        <Button className={s.google_btn} onClick={(e) => onLoginGoogleClick(e)}>
          <img className={s.google_icon} src={google} alt="" />
          <span className={s.btn_text}>Войти через google</span>
        </Button>
      </Form>
    </Modal>
  );
};
export default LoginModal;
