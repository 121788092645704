import { createAsyncThunk } from '@reduxjs/toolkit';
import $api from '../../http';
import { NomenclatureList, NomenclatureSearchParams } from './types';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';

export const fetchNomenclature = createAsyncThunk<NomenclatureList, NomenclatureSearchParams>(
  'nomenclature/fetchNomenclature',
  async (params) => {
    const { sortBy, order, category, search, currentPage, limit } = params;
    const { data } = await $api.get<NomenclatureList>(`/nomenclatures`, {
      params: pickBy(                                 
        {
          page: currentPage,
          limit,
          category,
          sortBy,
          order,
          search,
        },
        identity,
      ),
    });
    return data;
  },
);
