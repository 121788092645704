import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserLogin, UserRegistration } from './types';
import AuthService from '../../services/AuthService';
import {IAuthResponse} from '../../models/AuthResponse';


export const loginUser = createAsyncThunk<IAuthResponse, UserLogin>(
  'user/loginUser',
  async (params) => {
    const res = await AuthService.login(params.email, params.password);
    return res.data;
  },
);

export const registrationUser = createAsyncThunk<IAuthResponse, UserRegistration>(
  'user/registrationUser',
  async (params) => {
    const res = await AuthService.registration(params.email, params.password, params.surname, params.name, params.middlename, '');
    return res.data;
  },
);

export const logoutUser = createAsyncThunk(
    'user/logoutUser',
    async () => {
      await AuthService.logout();
      return null;
    },
  );
  
