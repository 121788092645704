import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Select, Spin } from "antd";
import React from "react";
import config from "../../../config";
import { InvoiceService } from "../../../services/InvoiceService";
import ShopService, { Organization } from "../../../services/ShopService";
import OrganizationControl, { OrganizationControlState } from "../../OrganizationPage/OrganizationControl";

const OrganizationOrder: React.FC = () => {
  enum Status {
    list,
    add,
  }
  const [organizationid, setOrganizationid] = React.useState("");
  const [items, setItems] = React.useState([] as Organization[]);
  const [status, setStatus] = React.useState<Status>(Status.list);
  const [enabledownload, setEnabledownload] = React.useState(true);
  const [invoice, setInvoice] = React.useState({} as any);
  const antIcon = <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />;
  const onSearch = (value: string) => {
    console.log("search:", value);
  };

  const onSaveCallback = (organization: Organization) => {
    setItems([...items, organization]);
    setOrganizationid(organization.organizationid);
    setStatus(Status.list);
  };

  const onCancelCallback = () => {
    setStatus(Status.list);
  };

  const downloadPDF = async (invoiceid: string) => {
    const accessToken = localStorage.getItem("token");
    setEnabledownload(false);
    const response = await fetch(config.API_URL + "/invoice/download_pdf/" + invoiceid, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response.status === 200) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "Заказ.pdf";
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
    setEnabledownload(true);
  };

  const makeInvoice = () => {
    if (!organizationid) {
      alert("Укажите организацию на которую оформить счет.");
      return;
    }
    if (invoice.invoiceid) {
      if (invoice.organizationid !== organizationid) {
        InvoiceService.updateOrganization(invoice.invoiceid, organizationid)
          .then((res) => {
            setInvoice(res.data);
            downloadPDF(res.data.invoiceid);
          })
          .catch((e: any) => {
            if (e.response) {
              alert(e.response.data.message); // setError(e.response.data.message);
            } else if (e.request) {
              alert(e.request); // setError(e.request);
            } else {
              alert(e.message); // setError(e.message);
            }
          });
      } else {
        downloadPDF(invoice.invoiceid);
      }
      return;
    }

    InvoiceService.makeFromCart("", organizationid)
      .then((res) => {
        setInvoice(res.data);
        downloadPDF(res.data.invoiceid);
      })
      .catch((e: any) => {
        if (e.response) {
          alert(e.response.data.message); // setError(e.response.data.message);
        } else if (e.request) {
          alert(e.request); // setError(e.request);
        } else {
          alert(e.message); // setError(e.message);
        }
      });
  };
  const effectFunc = () => {
    ShopService.organizationGet()
      .then((res) => {
        setItems(res.data.items);
      })
      .catch((e: any) => {
        if (e.response) {
          console.log(e.response.data);
        } else {
          console.log("Error", e.message);
        }
      });
  };

  React.useEffect(effectFunc, []);
  return (
    <>
      {status === Status.list && (
        <>
          <h2>Оформить на:</h2>
          <Select
            showSearch
            value={organizationid}
            placeholder="--Укажите юр. лицо--"
            optionFilterProp="children"
            onChange={(e) => setOrganizationid(e)}
            onSearch={onSearch}
            filterOption={(input, option) => {
              return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
            }}
            options={items.map((item: Organization) => ({
              value: item.organizationid,
              label: item.name,
            }))}
            style={{ width: "100%", marginBottom: "30px" }}
          />

          <Button block type="dashed" onClick={() => setStatus(Status.add)} style={{ marginBottom: "30px", borderColor: "#1e266d" }}>
            + Добавить новое юридическое лицо
          </Button>

          <Button block type="primary" size="large" disabled={!enabledownload} onClick={makeInvoice} icon={enabledownload && <DownloadOutlined style={{ fontSize: "20px" }} />}>
            {!enabledownload && <Spin indicator={antIcon} style={{ marginRight: "15px" }} />}
            Скачать счет
          </Button>
        </>
      )}
      {status === Status.add && <OrganizationControl state={OrganizationControlState.add} organization={{} as Organization} saveCallback={onSaveCallback} cancelCallback={onCancelCallback} />}
    </>
  );
};

export default OrganizationOrder;
