import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { ConfigProvider, FloatButton } from "antd";
import Cookies from "js-cookie";

import Loader from "./components/Loader/Loader";

import MainLayout from "./layouts/MainLayout";

import { cartFetch } from "./redux/cart/asyncActions";
import { currencyFetch } from "./redux/currency/asyncActions";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { selectUser } from "./redux/user/selectors";
import { setUser } from "./redux/user/slice";

import dateLocalize from "./utils/dateLocalize";

import UserService from "./services/UserService";

import CartPage from "./pages/CartPage";
import CartOrder from "./pages/CartPage/CartOrder";
import NomenclaturePage from "./pages/NomenclaturePage";
import NotFound from "./pages/NotFound";
import OrganizationList from "./pages/OrganizationPage";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";

const InvoicePage = React.lazy(() => import("./pages/Invoice/InvoicePage"));
const Shop = React.lazy(() => import("./pages/Shop"));
const MainPage = React.lazy(() => import("./pages/Main/MainPage"));
const ShowCaseDownloadPage = React.lazy(() => import("./pages/ShowCaseDownload/ShowCaseDownloadPage"));
const UserProfilePage = React.lazy(() => import("./pages/UserProfile"));

dateLocalize();

function App() {
  const [loading, setLoading] = React.useState(true);
  const { user } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(currencyFetch());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const cookie_accessToken = Cookies.get("accessToken");
    if (cookie_accessToken) {
      localStorage.setItem("token", cookie_accessToken);
    }
    Cookies.remove("accessToken");

    const token = localStorage.getItem("token");
    if (token && !user) {
      UserService.checkLogin()
        .then((res) => {
          dispatch(setUser(res.data));
          dispatch(cartFetch());
        })
        .catch((e) => {
          dispatch(setUser(null));
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (loading) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#1e266d",
            colorTextBase: "#1e266d",
            fontFamily: "'Montserrat', sans-serif",
            // colorText: "#1e266d",
          },
        }}
      >
        <Routes>
          <Route path="order" element={<CartOrder />} />
          <Route path="/" element={<MainLayout />}>
            <Route
              index
              element={
                <Suspense fallback={<Loader />}>
                  <MainPage />
                </Suspense>
              }
            />
            <Route
              path="/showcasedownload"
              element={
                <Suspense fallback={<Loader />}>
                  <ShowCaseDownloadPage />
                </Suspense>
              }
            />
            <Route
              path="/shop"
              element={
                <Suspense fallback={<Loader />}>
                  <Shop />
                </Suspense>
              }
            />
            <Route
              path="nomenclature/:id"
              element={
                <Suspense fallback={<Loader />}>
                  <NomenclaturePage />
                </Suspense>
              }
            />
            <Route
              path="/userprofile"
              element={
                <Suspense fallback={<Loader />}>
                  <UserProfilePage />
                </Suspense>
              }
            />
            <Route
              path="/organization"
              element={
                <Suspense fallback={<Loader />}>
                  <OrganizationList />
                </Suspense>
              }
            />
            <Route
              path="/organization"
              element={
                <Suspense fallback={<Loader />}>
                  <OrganizationList />
                </Suspense>
              }
            />
            <Route
              path="/invoice"
              element={
                <Suspense fallback={<Loader />}>
                  <InvoicePage />
                </Suspense>
              }
            />
            <Route
              path="cart"
              element={
                <Suspense fallback={<div>Идёт загрузка корзины...</div>}>
                  <CartPage />
                </Suspense>
              }
            />
            <Route
              path="privacy"
              element={
                <Suspense fallback={<div>Идёт загрузка корзины...</div>}>
                  <PrivacyPolicy />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="*"
            element={
              <Suspense fallback={<Loader />}>
                <NotFound />
              </Suspense>
            }
          />
        </Routes>
        <FloatButton.BackTop
          style={{
            boxShadow: "rgba(0, 0, 0, 1) 0px 0px 3px 0px, rgba(30, 38, 109, 1) 0px 18px 36px -18px inset",
            width: "50px",
            height: "50px",
            bottom: "70px",
          }}
        />
      </ConfigProvider>
    </>
  );
}

export default App;
