import { selectCart } from "@/redux/cart/selectors";
import { useAppSelector } from "@/redux/hooks";
import { selectUser } from "@/redux/user/selectors";
import { MenuOutlined, OrderedListOutlined, SettingOutlined, ShoppingCartOutlined, TeamOutlined } from "@ant-design/icons";
import { Badge, Button, Col, Drawer, Row, Space } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import LoginModal from "../LoginModal";
import RegistrationModal from "../RegistrationModal";

import s from "./Drawer.module.scss";

const DrawerBlock: React.FC = () => {
  const { user } = useAppSelector(selectUser);
  const [open, setOpen] = useState(false);
  const [isLoginShow, setIsLoginShow] = useState(false);

  const { items } = useAppSelector(selectCart);
  const totalCount = items.reduce((sum: number, item: any) => sum + item.cnt, 0);
  const [isRegShow, setIsRegShow] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };
  const onLoginClick = () => {
    setIsLoginShow(!isLoginShow);
    setOpen(false);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Badge count={totalCount}>
        <Link to={""} onClick={showDrawer} style={{ color: "#1e266d" }}>
          <MenuOutlined style={{ fontSize: "35px" }} />
        </Link>
      </Badge>
      <Drawer
        height={"auto"}
        className={s.drawer}
        closable={false}
        title={user ? user.email : "Меню"}
        placement="top"
        onClose={onClose}
        open={open}
        extra={
          !user && (
            <Button type="primary" onClick={onLoginClick}>
              Войти
            </Button>
          )
        }
        footer={
          <Space>
            <Button onClick={onClose}>Закрыть</Button>
          </Space>
        }
      >
        <Row justify="center" className={s.row}>
          <Col span={22}>
            <Link to="/cart" onClick={onClose}>
              <Button
                icon={
                  <Badge count={totalCount}>
                    <ShoppingCartOutlined style={{ fontSize: "30px", margin: "5px 7px 5px 0", color: "white" }} />
                  </Badge>
                }
                type="primary"
                block
                style={{ height: "auto" }}
              >
                <span
                  style={{
                    transform: "translateY(-6px)",
                  }}
                >
                  Корзина
                </span>
              </Button>
            </Link>
          </Col>
        </Row>
        {user && (
          <>
            <Row justify="center" className={s.row}>
              <Col span={22}>
                <Link to="/invoice" onClick={onClose}>
                  <Button icon={<OrderedListOutlined style={{ fontSize: "30px", margin: "5px 7px 5px 0" }} />} type="primary" block style={{ height: "auto" }}>
                    <span
                      style={{
                        transform: "translateY(-6px)",
                      }}
                    >
                      Мои заказы
                    </span>
                  </Button>
                </Link>
              </Col>
            </Row>
            <Row justify="center" className={s.row}>
              <Col span={22}>
                <Link to="/organization" onClick={onClose}>
                  <Button icon={<TeamOutlined style={{ fontSize: "30px", margin: "5px 7px 5px 0" }} />} type="primary" block style={{ height: "auto" }}>
                    <span
                      style={{
                        transform: "translateY(-6px)",
                      }}
                    >
                      Мои юр. лица
                    </span>
                  </Button>
                </Link>
              </Col>
            </Row>
            <Row justify="center" className={s.row}>
              <Col span={22}>
                <Link to="/userprofile" onClick={onClose}>
                  <Button icon={<SettingOutlined style={{ fontSize: "30px", margin: "5px 7px 5px 0" }} />} type="primary" block style={{ height: "auto" }}>
                    <span
                      style={{
                        transform: "translateY(-5px)",
                      }}
                    >
                      Параметры аккаунта
                    </span>
                  </Button>
                </Link>
              </Col>
            </Row>
          </>
        )}
      </Drawer>
      {isLoginShow && (
        <LoginModal
          registrationClick={() => {
            setIsLoginShow(false);
            setIsRegShow(true);
          }}
          handleClose={() => {
            setIsLoginShow(false);
          }}
        />
      )}
      {isRegShow && (
        <RegistrationModal
          LoginClick={() => {
            setIsLoginShow(true);
            setIsRegShow(false);
          }}
          handleClose={() => {
            setIsRegShow(false);
          }}
        />
      )}
    </>
  );
};

export default DrawerBlock;
