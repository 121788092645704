import $api from "../http";
import {AxiosResponse} from 'axios';
import {Currency} from "../redux/currency/types";

export default class CurrencyService {
    static fetchCurrency(): Promise<AxiosResponse<Currency>> {
        return $api.get<Currency>('/currency')
    }

}

