import { createSlice } from '@reduxjs/toolkit';
import { fetchNomenclature } from './asyncActions';
import { NomenclatureSliceState, NomenclatureStatus } from './types';

const initialState: NomenclatureSliceState = {
  items: [],
  totalcount: 0,
  status: NomenclatureStatus.LOADING, // loading | success | error
};

const nomenclatureSlice = createSlice({
  name: 'nomenclature',
  initialState,
  reducers: {  },
  extraReducers: (builder) => {
    builder.addCase(fetchNomenclature.pending, (state) => {
      state.status = NomenclatureStatus.LOADING;
      state.items = [];
      state.totalcount = 0;
    });

    builder.addCase(fetchNomenclature.fulfilled, (state, action) => {
      state.items = action.payload.items;
      state.totalcount = action.payload.totalcount;
      state.status = NomenclatureStatus.SUCCESS;
    });

    builder.addCase(fetchNomenclature.rejected, (state) => {
      state.status = NomenclatureStatus.ERROR;
      state.items = [];
      state.totalcount = 0;
    });
  },
});

export default nomenclatureSlice.reducer;
