import { Col, Button, Row } from "antd";
import React from "react";
import Loader from "../../components/Loader/Loader";
import ShopService, { Organization } from "../../services/ShopService";
import OrganizationControl, { OrganizationControlState } from "./OrganizationControl";
import OrganizationItem from "./OrganizationItem";

import s from "./Organization.module.scss";
import ErrorModule from "@/components/ErrorModule";

type OrganizationEx = Organization & { hasinvoice: boolean };

const OrganizationList: React.FC = () => {
  enum Status {
    list,
    add,
  }

  const [loading, setLoading] = React.useState(true);
  const [items, setItems] = React.useState([] as OrganizationEx[]);
  const [error, setError] = React.useState();
  const [status, setStatus] = React.useState<Status>(Status.list);

  const onSaveCallback = (organization: Organization) => {
    setItems([...items, { ...organization, hasinvoice: false }]);
    setStatus(Status.list);
  };

  const onCancelCallback = () => {
    setStatus(Status.list);
  };

  const onDeleteCallback = (organizationid: string) => {
    const items_new = items.filter((item: any) => item.organizationid !== organizationid);
    setItems(items_new);
  };

  const effectFunc = () => {
    setLoading(true);
    setError(undefined);
    ShopService.organizationGet()
      .then((res: any) => {
        setItems(res.data.items);
      })
      .catch((e: any) => {
        if (e.response) {
          console.log(e.response.data);
          setError(e.response.data);
        } else {
          console.log("Error", e.message);
          setError(e.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  React.useEffect(effectFunc, []);

  if (loading) return <Loader />;

  if (error) return <ErrorModule />;

  return (
    <div className={s.organization}>
      <Col style={{ textAlign: "center" }}>
        <h1>Мои юр. лица</h1>
      </Col>
      <Row>
        <Col xs={{ span: 22, offset: 1 }} sm={{ span: 22, offset: 1 }} md={{ span: 22, offset: 1 }} lg={{ span: 10 }} xl={{ span: 10, offset: 2 }}>
          {status !== Status.add && (
            <>
              <Button block type="dashed" onClick={() => setStatus(Status.add)} style={{ marginBottom: "30px", borderColor: "#1e266d" }}>
                + Добавить новое юридическое лицо
              </Button>

              {items.map((item: OrganizationEx) => (
                <OrganizationItem
                  key={item.organizationid}
                  organization={{ ...item } as Organization}
                  hasinvoice={item.hasinvoice}
                  deleteCallBack={() => {
                    onDeleteCallback(item.organizationid);
                  }}
                />
              ))}
            </>
          )}

          {status === Status.add && (
            <OrganizationControl
              state={OrganizationControlState.add}
              organization={{ organizationid: "", name: "", inn: "", namefull: "", kpp: "", pookpo: "", code: "" }}
              saveCallback={onSaveCallback}
              cancelCallback={onCancelCallback}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default OrganizationList;
