import $api from "../http";
import {AxiosResponse} from 'axios';
import {Cart} from "../redux/cart/types";

export default class CartService {
    static async getAll(): Promise<AxiosResponse<Cart[]>> {
        return $api.get<Cart[]>('/cart')
    }

    static async add(nomenclatureid: String): Promise<AxiosResponse<Cart>> {
        const res = $api.get<Cart>(`/cart/add/${nomenclatureid}`);
        return res;
    }

    static async dec(nomenclatureid: String): Promise<AxiosResponse<Cart>> {
        const res = $api.get<Cart>(`/cart/dec/${nomenclatureid}`);
        return res;
    }

    static async del(nomenclatureid: String): Promise<AxiosResponse<Cart>> {
        const res = $api.delete<Cart>(`/cart/del/${nomenclatureid}`);
        return res;
    }

    static async clear(): Promise<AxiosResponse<string>> {
        const res = $api.delete<string>(`/cart/clear`);
        return res;
    }
     
}

