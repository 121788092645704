export type Cart = {
  // cartid: string;
  nomenclatureid: string;
  name: string;
  price: number;
  imageUrl: string;
  cnt: number;
};

export interface CartSliceState {
  totalPrice: number;
  items: Cart[];
  status: CartStatus;
}

export enum CartStatus {
  LOADING = 'loading',
  SUCCESS = 'completed',
  ERROR = 'error',
}

