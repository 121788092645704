import { Col, Row } from "antd";
import React from "react";

import s from "./Privacy.module.scss";

const PrivacyPolicy: React.FC = () => {
  return (
    <Row justify={"center"} className={s.privacy}>
      <Col span={20}>
        <h1>ЛИЦЕНЗИОННОЕ СОГЛАШЕНИЕ С КОНЕЧНЫМ ПОЛЬЗОВАТЕЛЕМ</h1>
        <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
          <p>ВНИМАТЕЛЬНО ОЗНАКОМЬТЕСЬ С ЛИЦЕНЗИОННЫМ СОГЛАШЕНИЕМ НА ИСПОЛЬЗОВАНИЕ ПРОГРАММНОГО ОБЕСПЕЧЕНИЯ LDAP сервер для 1С!</p>
        </h3>
        <ul style={{ textAlign: "justify" }}>
          <li>
            <p>1. Исключительное право на программное обеспечение LDAP сервер для 1С (далее – ПО) принадлежат ООО «Негоциант» (далее – Правообладатель).</p>
          </li>
          <li>
            <p>
              2. Настоящее лицензионное соглашение с конечным пользователем (далее - Соглашение) заключается с целью установления прав на программное обеспечение и документацию, записанные на
              соответствующих носителях или доступные для загрузки по сети Интернет, а также электронную лицензию и/или ключ защиты, определяющий вариант поставки ПО и срок действия лицензии, и
              является офертой ООО «Негоциант» к физическому или юридическому лицу (далее – Пользователь).
            </p>
          </li>
          <li>
            <p>
              3. Установка ПО в память ЭВМ рассматривается как безусловное согласие Пользователя с условиями настоящего Соглашения. В случае несогласия с каким-либо из условий настоящего Соглашения
              Пользователь не имеет права продолжать установку ПО.
            </p>
          </li>
          <li>
            <p>
              4. Настоящее Соглашение предоставляет Пользователю право установки (инсталляции), запуска и использования одного экземпляра ПО на одну ЭВМ, в рамках функциональных возможностей, в
              соответствии с назначением и правилами пользования ПО, изложенными в эксплуатационной документации.
            </p>
          </li>
          <li>
            <p>
              5. Пользователь имеет право использовать ПО на правах простой (неисключительной) лицензии с момента активации ПО, приобретенного у Правообладателя или у официального партнера,
              дистрибьютора в течение срока действия приобретенной лицензии.
            </p>
          </li>
          <li>
            <p>
              6. Моментом активации ПО считается дата скачивания файла с сайта{" "}
              <a href="http://ldap.pro/">
                <u>http://ldap.pro.</u>
              </a>
            </p>
          </li>
          <li>
            <p>
              7. Лицензии действительны в течение 1 календарного года с момента активации ПО, кроме случаев замены оборудования, на котором используется ПО, при невозможности деактивации лицензии на
              старом.
            </p>
          </li>
          <li>
            <p>
              8. Пользователь обязуется не модифицировать, не адаптировать, не выполнять обратное проектирование, не декомпилировать, не дизассемблировать, не расшифровывать, не копировать, не
              создавать производные продукты, не транслировать ПО или документацию, или в ином порядке пытаться извлечь исходный код ПО, не вносить какие-либо изменения в объектный код ПО, в том числе
              не поручать иным лицам осуществлять указанные действия без согласия Правообладателя, за исключением тех случаев, когда указанные действия необходимы для осуществления Лицензиатом
              лицензионных прав, предусмотренных условиями Соглашения.
            </p>
          </li>
          <li>
            <p>9. Пользователь обязуется не совершать действия, нарушающие исключительные и авторские права Правообладателя.</p>
          </li>
          <li>
            <p>10. Настоящее Соглашение распространяет свое действие на весь период использования ПО. При прекращении использования ПО Пользователь обязан удалить ПО из памяти ЭВМ.</p>
          </li>
          <li>
            <p>
              11. Правообладатель не несет ответственность за фактические, косвенные и второстепенные убытки, включая в числе прочего упущенную выгоду и убытки в результате: потери прибыли, потери
              доходов, потери и получения доступа к конфиденциальной информации, персональных данных и иной информации, прерывания деятельности, в результате любых других материальных или иных
              убытков, возникающих в связи с использованием или невозможностью использования ПО или документации, а также при использовании стороннего программного обеспечения, предоставляемого
              третьими лицами.
            </p>
          </li>
          <li>
            <p>
              12. При нарушении Пользователем условий Соглашения, порядка использования ПО, Правообладатель вправе прекратить и/или отказать в предоставлении прав на использование ПО без возмещения
              каких-либо убытков.
            </p>
          </li>
          <li>
            <p>
              13. ПО предоставляется по принципу «как есть» и Правообладатель не гарантирует, что все его функциональные возможности будут отвечать ожиданиям Пользователя или смогут быть применимы для
              конкретной его цели.
            </p>
          </li>
          <li>
            <p>
              14. Действие настоящего Соглашения распространяется на все последующие обновления/новые версии ПО. Соглашаясь с установкой обновления/новой версии ПО, Пользователь принимает условия
              настоящего Соглашения для соответствующих обновлений/новых версий ПО, если обновление/установка новой версии ПО не сопровождается иным лицензионным соглашением.
            </p>
          </li>
          <li>
            <p>
              15. Техническая поддержка оказывается Правообладателем по каналам, указанным на сайте{" "}
              <a href="https://ldap.pro/contacts">
                <u>https://ldap.pro/contacts</u>
              </a>
            </p>
          </li>
          <li>
            <p>
              16. Настоящее лицензионное соглашение может изменяться Правообладателем в одностороннем порядке.
              {/*  Уведомление Пользователя о внесенных изменениях в условия настоящей лицензии публикуется
              на новостной странице сайта Правообладателя{" "}
              <a href="http://ldap.pro/news">
                <u>http://ldap.pro/news.</u>
              </a> */}{" "}
              Актуальная версия лицензионного соглашения доступна на сайте Правообладателя в разделе «Лицензионное соглашение»{" "}
              <a href="https://ldap.pro/privacy">
                <u>https://ldap.pro/privacy</u>
              </a>{" "}
              и дистрибутивах ПО.
            </p>
          </li>
          <li>
            <p>17. Указанные изменения в условиях лицензионного соглашения вступают в силу с даты их публикации, если иное не оговорено в соответствующей публикации.</p>
          </li>
          <li>
            <p>18. Принимая настоящее Соглашение Пользователь подтверждает свое согласие на получение рекламных сообщений от Правообладателя по электронной почте.</p>
          </li>
        </ul>
        <div style={{ fontWeight: "bold", textAlign: "center", marginBottom: "20px" }}>
          <p>ООО PAUT.RU ©</p>
          <p>Адрес: 660118, г.Красноярск, Северное шоссе, д.7 корп.в/2, пом.13</p>
          <p>ИНН/КПП 2465328990 / 246501001 ОГРН 1192468041941</p>

          <a href="tel:+7(391)203-00-15">+7(391)203-00-15</a>
        </div>
      </Col>
    </Row>
  );
};

export default PrivacyPolicy;
