export type User = {
    userprofileid: string;
    email: string;
  };

  export type UserLogin = {
    email: string;
    password: string;
  };

  export type UserRegistration = {
    email: string;
    password: string;
    name: string;
    surname: string;
    middlename: string;
  };
  
  export enum Status {
    LOADING = 'loading',
    SUCCESS = 'completed',
    ERROR = 'error',
  }
  
  export interface UserSliceState {
    user: User | null;
    status: Status;
  }
  