import React from "react";
import { Link } from "react-router-dom";
import PeopleOrder from "./BuyerOrder/PeopleOrder";
import OrganizationOrder from "./BuyerOrder/OrganizationOrder";
import { useAppSelector } from "../../redux/hooks";
import { selectCurrency } from "../../redux/currency/selectors";
import { selectCart } from "../../redux/cart/selectors";

import s from "./Cart.module.scss";
import { Row, Col, Avatar, Segmented } from "antd";
import { TeamOutlined, UserOutlined } from "@ant-design/icons";

import logosvg from "@/assets/img/svg/logo.svg";

const CartOrder: React.FC = () => {
  const currency = useAppSelector(selectCurrency);
  const { totalPrice, items } = useAppSelector(selectCart);
  const totalCount = items.reduce((sum: number, item: any) => sum + item.cnt, 0);
  const [toggleclient, setToggleMapAddress] = React.useState(true);

  const toggleSwitchChange = () => {
    setToggleMapAddress(!toggleclient);
  };

  return (
    <Row justify="center">
      <Col xs={{ span: 23 }} sm={{ span: 23 }} md={{ span: 18 }} lg={{ span: 14 }} xl={{ span: 12 }}>
        <Row align="middle" className="text-center align-items-center my-4" style={{ margin: "30px 0" }}>
          <Col span={12}>
            <Link to="/">
              <div className={s.logo}>
                <div></div>
              </div>
              <img src={logosvg} alt="" style={{ width: "70px", height: "70px" }} />
            </Link>
          </Col>
          <Col span={12} style={{ alignItems: "center" }}>
            <div>
              <p>Оформляем {totalCount} товар(-ов, -а)</p>
              <p>
                на сумму {totalPrice.toLocaleString()} {currency.nameshort}
              </p>
            </div>
          </Col>
        </Row>
        <hr />
        <h2>Данные покупателя</h2>
        <Segmented
          onChange={toggleSwitchChange}
          block
          options={[
            {
              label: (
                <div style={{ padding: 4 }}>
                  <Avatar style={{ backgroundColor: "#1e266d" }}>
                    <UserOutlined style={{ fontSize: "30px" }} />
                  </Avatar>
                  <div>Физ. лицо</div>
                </div>
              ),
              value: "Физ. лицо",
            },
            {
              label: (
                <div style={{ padding: 4 }}>
                  <Avatar style={{ backgroundColor: "#1e266d" /* height: "50px", width: "60px" */ }}>
                    <TeamOutlined style={{ fontSize: "30px" }} />
                  </Avatar>
                  <div>Организация</div>
                </div>
              ),
              value: "Организация",
            },
          ]}
          onResize={undefined}
          onResizeCapture={undefined}
        />
        {/* <br />
        <br /> */}
        {/* <Switch checkedChildren="Физическое лицо" unCheckedChildren="Юридическое лицо" defaultChecked onChange={toggleSwitchChange} style={{ fontSize: "24px" }} /> */}

        <div>
          {toggleclient && <PeopleOrder />}
          {!toggleclient && <OrganizationOrder />}
        </div>
      </Col>
    </Row>
  );
};

export default CartOrder;
