import { Badge, Button, Col, Divider, Dropdown, Row } from "antd";
import React, { useState } from "react";

import { droplinks } from "./DropdownData";

import { Link } from "react-router-dom";
import LoginModal from "../../LoginModal";

import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { selectUser } from "@/redux/user/selectors";
import { logoutUser } from "@/redux/user/asyncActions";
import { selectCart } from "@/redux/cart/selectors";

import Cookies from "js-cookie";
import RegistrationModal from "@/components/RegistrationModal";
import { ShoppingCartOutlined } from "@ant-design/icons";

import logosvg from "@/assets/img/svg/logo.svg";

import cn from "classnames";
import s from "./Hat.module.scss";
import Drawer from "@/components/Drawer/Drawer";

export const Hat: React.FC = () => {
  const [isLoginShow, setIsLoginShow] = useState(false);
  const [isRegShow, setIsRegShow] = useState(false);
  const [droplink, setLink] = React.useState(false);
  const { user } = useAppSelector(selectUser);

  const dispatch = useAppDispatch();

  const { items } = useAppSelector(selectCart);
  const totalCount = items.reduce((sum: number, item: any) => sum + item.cnt, 0);

  const setSelected = () => {
    setLink(true);
  };
  const onLoginClick = () => {
    setIsLoginShow(!isLoginShow);
  };
  const onRegClick = () => {
    setIsRegShow(!isRegShow);
  };

  return (
    <>
      <Row className={s.hat} align="middle" justify="center">
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 20 }} lg={{ span: 20 }} xl={{ span: 18 }}>
          <Row justify="space-between" align="middle">
            <Col xs={{ span: 6 }} sm={{ span: 4 }} md={{ span: 16 }} lg={{ span: 12 }} xl={{ span: 11 }}>
              <Link to="/">
                <Row align="middle">
                  <Col style={{ padding: "20px 10px 20px 20px" }}>
                    <img src={logosvg} alt="" style={{ width: "50px", height: "50px" }} />
                  </Col>
                  <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 14 }} lg={{ span: 14 }} xl={{ span: 14 }}>
                    <h2 style={{ color: "#1e266d" }}>LDAP сервер для 1С</h2>
                  </Col>
                </Row>
              </Link>
            </Col>

            <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 0 }} lg={{ span: 12 }} xl={{ span: 13 }}>
              <Row justify="end" align="middle">
                <Col style={{ marginRight: "70px" }}>
                  <Link to={"/cart"}>
                    <Badge /* color="#1e266d" */ count={totalCount}>
                      <span style={{ fontSize: "20px" }}>Корзина</span>
                      <ShoppingCartOutlined style={{ height: "30px", marginLeft: "8px", fontSize: "20px" }} />
                    </Badge>
                  </Link>
                </Col>
                <Col style={{ textAlign: "end", justifyContent: "end" }}>
                  {!user && (
                    <>
                      <Button type="primary" onClick={onLoginClick}>
                        Вход
                      </Button>
                      <Button className={s.reg_btn} onClick={onRegClick}>
                        Регистрация
                      </Button>
                    </>
                  )}

                  {user && (
                    <Dropdown
                      placement="bottom"
                      dropdownRender={() => (
                        <div className={cn("dropdown-content", s.dropdown)}>
                          {droplinks.map((item) => (
                            <Link className={cn(droplink ? "animate__backOutRight" : "")} key={item.key} to={item.link} onClick={setSelected}>
                              <p>{item.label}</p>
                            </Link>
                          ))}
                          <Divider />
                          <a
                            href="/"
                            className={s.dropdown_item}
                            onClick={(e) => {
                              dispatch(logoutUser());
                              localStorage.removeItem("token");
                              Cookies.remove("accessToken");
                              window.location.href = "/";
                            }}
                          >
                            <p className={s.logout}>Выйти</p>
                          </a>
                        </div>
                      )}
                    >
                      <Button className={s.drop_btn} onClick={(e) => e.preventDefault()}>
                        <h4 className={s.login}>{user.email}</h4>
                      </Button>
                    </Dropdown>
                  )}
                </Col>
              </Row>
            </Col>
            <Col xs={{ span: 5 }} sm={{ span: 3 }} md={{ span: 1 }} lg={{ span: 0 }} xl={{ span: 0 }}>
              <Drawer />
            </Col>
          </Row>
        </Col>
      </Row>
      {isLoginShow && (
        <LoginModal
          registrationClick={() => {
            setIsLoginShow(false);
            setIsRegShow(true);
          }}
          handleClose={() => {
            setIsLoginShow(false);
          }}
        />
      )}
      {isRegShow && (
        <RegistrationModal
          LoginClick={() => {
            setIsLoginShow(true);
            setIsRegShow(false);
          }}
          handleClose={() => {
            setIsRegShow(false);
          }}
        />
      )}
    </>
  );
};
