import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Modal, Row } from "antd";
import React, { useState } from "react";
import ShopService, { Organization } from "../../services/ShopService";

import s from "./Organization.module.scss";

import org from "@/assets/img/organization.png";

type OrganizationItemProps = {
  organization: Organization;
  hasinvoice: boolean;

  deleteCallBack: () => void;
};

const OrganizationItem: React.FC<OrganizationItemProps> = ({ organization, hasinvoice, deleteCallBack }) => {
  enum Status {
    collapsed,
    expanded,
    edit,
  }

  const [original, setOriginal] = React.useState({ ...organization } as Organization);
  const [candidat, setCandidat] = React.useState({ ...organization } as Organization);
  const [status, setStatus] = React.useState<Status>(Status.collapsed);
  const [deleted, setDeleted] = React.useState();
  const [modalOpen, setModalOpen] = useState(false);
  const cancelUpdateOrganization = () => {
    setCandidat(original);
    setStatus(Status.collapsed);
  };
  const onAcceptDeleteModal = () => {
    deleteOrganization(original.organizationid);
    setModalOpen(false);
  };
  const updateOrganization = () => {
    ShopService.organizationUpdate(candidat)
      .then(() => {
        setOriginal(candidat);
        setStatus(Status.collapsed);
      })
      .catch((e: any) => {
        if (e.response) {
          console.error(e.response.data);
        } else {
          console.error("Error", e.message);
        }
      });
  };
  const deleteOrganization = (organizationid: string) => {
    setDeleted(deleted);
    ShopService.organizationDelete(organizationid)
      .then(() => {
        deleteCallBack();
      })
      .catch((e: any) => {
        if (e.response) {
          console.error(e.response.data);
        } else {
          console.error("Error", e.message);
        }
      });
  };

  return (
    <Card style={{ marginBottom: "50px" }} className={deleted ? "animate_animated animate__fadeOutBottomLeft" : ""}>
      <div>
        {status === Status.collapsed && (
          <>
            <Row align="middle" style={{ marginBottom: "20px" }}>
              <Col xs={3}>
                <img src={org} alt="" style={{ width: "100%" }} />
              </Col>
              <Col xs={14}>
                <h2 style={{ margin: "0 0 0 10px" }}>{original.name}</h2>
              </Col>
              {!hasinvoice && (
                <span className={s.deleteorg_btn} role="img" onClick={() => setModalOpen(true)}>
                  <CloseOutlined />
                </span>
              )}
            </Row>
            <Modal
              title={
                <div style={{ display: "flex" }}>
                  <ExclamationCircleOutlined style={{ fontSize: "40px", color: "orange" }} />
                  <h3 style={{ marginLeft: "30px" }}>Подтвердите удаление организации</h3>
                </div>
              }
              centered
              open={modalOpen}
              onOk={onAcceptDeleteModal}
              onCancel={() => setModalOpen(false)}
              okText="Удалить"
              cancelText="Отмена"
            ></Modal>
          </>
        )}
        {status === Status.edit && (
          <>
            <h2 style={{ margin: "0", marginBottom: "30px" }}>Редактирование юр. лица</h2>
            <Form initialValues={{ ...candidat }} labelCol={{ span: 9 }} wrapperCol={{ span: 16 }} autoComplete="off">
              <Form.Item label="Наименование" name="name">
                <Input onChange={(e) => setCandidat({ ...candidat, name: e.target.value })} />
              </Form.Item>
              <Form.Item label="Полное наименование" name="namefull">
                <Input onChange={(e) => setCandidat({ ...candidat, namefull: e.target.value })} />
              </Form.Item>
              <Form.Item label="ИНН" name="inn">
                <Input onChange={(e) => setCandidat({ ...candidat, inn: e.target.value })} />
              </Form.Item>
              <Form.Item label="КПП" name="kpp">
                <Input onChange={(e) => setCandidat({ ...candidat, kpp: e.target.value })} />
              </Form.Item>
            </Form>
          </>
        )}
        {status === Status.expanded && (
          <>
            <h2 style={{ margin: "0", marginBottom: "30px" }}>Расширенная информация юр. лица</h2>

            <Row className={s.explist}>
              <Col span={12}>
                <span>Наименование:</span>
              </Col>
              <Col span={12}>
                <span>{original.name}</span>
              </Col>
            </Row>
            <Row className={s.explist}>
              <Col span={12}>
                <span>Полное наименование:</span>
              </Col>
              <Col span={12}>
                <span>{original.namefull}</span>
              </Col>
            </Row>
            <Row className={s.explist}>
              <Col span={12}>
                <span>ИНН:</span>
              </Col>
              <Col span={12}>
                <span>{original.inn}</span>
              </Col>
            </Row>
            <Row className={s.explist}>
              <Col span={12}>
                <span>КПП:</span>
              </Col>
              <Col span={12}>
                <span>{original.kpp}</span>
              </Col>
            </Row>
          </>
        )}
      </div>
      <Row>
        {status !== Status.edit && (
          <Col span={11}>
            <Button className={s.edit} block type="primary" onClick={() => setStatus(Status.edit)}>
              Редактировать
            </Button>
          </Col>
        )}
        {status === Status.collapsed && (
          <Col span={11} offset={2}>
            <Button className={s.expand} block onClick={() => setStatus(Status.expanded)}>
              Развернуть
            </Button>
          </Col>
        )}
        {status === Status.edit && (
          <>
            <Col span={11}>
              <Button className={s.save} block type="primary" onClick={updateOrganization}>
                Сохранить
              </Button>
            </Col>
            <Col span={11} offset={2}>
              <Button className={s.cancel} block onClick={cancelUpdateOrganization}>
                Отменить
              </Button>
            </Col>
          </>
        )}
        {status === Status.expanded && (
          <Col span={11} offset={2}>
            <Button className={s.collapse} block onClick={() => setStatus(Status.collapsed)}>
              Свернуть
            </Button>
          </Col>
        )}
      </Row>
    </Card>
  );
};

export default OrganizationItem;
