import React from "react";
import { ShoppingOutlined, FlagOutlined, VerticalAlignBottomOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Menu } from "antd";
import { Link, useLocation } from "react-router-dom";

import s from "./Nav.module.scss";

const items: MenuProps["items"] = [
  {
    label: (
      <Link to="/">
        <span className={s.label}>Главная</span>
      </Link>
    ),
    key: "/",
    icon: <FlagOutlined className={s.icon} />,
  },
  {
    label: (
      <Link to={"/shop"}>
        <span className={s.label}>Магазин</span>
      </Link>
    ),
    key: "/shop",
    icon: <ShoppingOutlined className={s.icon} />,
  },
  {
    label: (
      <Link to={"/showcasedownload"}>
        <span className={s.label}>Загрузки</span>
      </Link>
    ),
    key: "/showcasedownload",
    icon: <VerticalAlignBottomOutlined className={s.icon} />,
  },
];

const NavBar: React.FC = () => {
  const location = useLocation();

  return <Menu className={s.menu} selectedKeys={[location.pathname]} mode="horizontal" items={items} />;
};

export default NavBar;
