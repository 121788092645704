import $api from "../http";
import {AxiosResponse} from 'axios';
import {IAuthResponse} from "../models/AuthResponse";

export default class AuthService {
    static async login(email: string, password: string): Promise<AxiosResponse<IAuthResponse>> {
        return $api.post<IAuthResponse>('/login', {email, password})
    }

    static async registration(email: string, password: string, surname: string, name: string, middlename: string, phone: string): Promise<AxiosResponse<IAuthResponse>> {
        return $api.post<IAuthResponse>('/registration', {email, password, surname, name, middlename, phone})
    }

    static async logout(): Promise<void> {
        return $api.post('/logout')
    }
}

