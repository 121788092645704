import { Button, Col, Form, Radio, Row, Tooltip } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import { selectUser } from "../../../redux/user/selectors";
import { InvoiceService } from "../../../services/InvoiceService";
import UserProfileService from "../../../services/UserProfileService";

import s from "@/pages/CartPage/Cart.module.scss";

const PeopleContent: React.FC = () => {
  const [people, setPeople] = React.useState<any>({ surname: "", name: "", middlename: "", phone: "" });
  const { user } = useAppSelector(selectUser);

  const makeInvoice = () => {
    InvoiceService.makeFromCart("")
      .then((res: any) => {
        return InvoiceService.makeYookassa(res.data.invoiceid);
      })
      .then((res) => {
        window.location.href = res.data.payment.confirmation.confirmation_url;
      })
      .catch((e: any) => {
        if (e.response) {
          alert(e.response.data.message); // setError(e.response.data.message);
        } else if (e.request) {
          alert(e.request); // setError(e.request);
        } else {
          alert(e.message); // setError(e.message);
        }
      });
  };

  React.useEffect(() => {
    UserProfileService.peopleGet()
      .then((res) => {
        setPeople(res.data);
      })
      .catch((e: any) => {
        if (e.response) {
          console.log(e.response.data);
        } else {
          console.log("Error", e.message);
        }
      });
  }, []);

  return (
    <>
      <Form labelCol={{ span: 3 }} wrapperCol={{ span: 21 }} autoComplete="off" style={{ margin: "30px 0" }}>
        <Form.Item label="ФИО:">{people.surname + " " + people.name + " " + people.middlename}</Form.Item>
        <Form.Item label="Телефон:">{people.phone}</Form.Item>
        <Form.Item label="E-mail:">{user?.email}</Form.Item>
      </Form>

      <h2 className="mt-3">Выберите способ оплаты</h2>

      <Radio.Group defaultValue="a" buttonStyle="solid" style={{ marginTop: 16 }}>
        <Tooltip title="Ю-Money" color={"#8b3ffd"} style={{ color: "#8b3ffd" }}>
          <Radio.Button value="a" style={{ height: "100%" }}>
            <img height={40} width={40} src="/img/umoney.png" alt="" style={{ padding: "0px", transform: "translateY(5px)" }} />
          </Radio.Button>
        </Tooltip>
        <Tooltip title="Система быстрых платежей" color={"#f1b945"} style={{ color: "#8b3ffd" }}>
          <Radio.Button value="b" style={{ height: "100%" }} disabled>
            <img height={40} width={40} src="/img/sbp.png" alt="" style={{ padding: "0px", transform: "translateY(5px)" }} />
          </Radio.Button>
        </Tooltip>
        <Tooltip title="Сбер" color={"#009c2e"} style={{ color: "#8b3ffd" }}>
          <Radio.Button value="c" style={{ height: "100%" }} disabled>
            <img height={40} width={40} src="/img/logo-sber.png" alt="" style={{ padding: "0px", transform: "translateY(5px)" }} />
          </Radio.Button>
        </Tooltip>
      </Radio.Group>
      <p className="my-3">
        Оплачивая заказ, Вы соглашаетесь с условиями{" "}
        <Link className={s.privacy} to="/privacy">
          лицензионного соглашения
        </Link>
        .
      </p>
      <Row align="middle" justify="space-between">
        <Col xs={{ span: 24 }} sm={{ span: 11 }}>
          <Button type="primary" block size="large" onClick={makeInvoice}>
            Оплатить заказ
          </Button>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 11 }}>
          <p className="mb-0">Чек будет отправлен на эл. почту {user?.email}</p>
        </Col>
      </Row>
    </>
  );
};

export default PeopleContent;
