import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { loginUser, logoutUser, registrationUser } from './asyncActions';
import { User, UserSliceState, Status } from './types';

const initialState: UserSliceState = {
  user: null,
  status: Status.LOADING, // loading | success | error
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
     setUser(state, action: PayloadAction<User|null>) {
      // console.log('userSlice setUser', action.payload);
      
       state.user = action.payload;
       state.status = Status.SUCCESS;
       if (!state.user)
       {
         localStorage.removeItem('token');
       }
      },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state, action) => {
      state.status = Status.LOADING;
      state.user = null;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.user = {...action.payload} as User;
      localStorage.setItem('token', action.payload.accessToken);
      state.status = Status.SUCCESS;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.status = Status.ERROR;
      state.user = null;
    });

    builder.addCase(logoutUser.fulfilled, (state, action) => {
      state.user = null;
      state.status = Status.SUCCESS;
      console.log('localStorage.removeItem');
      localStorage.removeItem('token');
    });

    builder.addCase(logoutUser.rejected, (state, action) => {
      state.status = Status.ERROR;
      state.user = null;
    });

    builder.addCase(registrationUser.pending, (state, action) => {
        state.status = Status.LOADING;
        state.user = null;
      });
    builder.addCase(registrationUser.fulfilled, (state, action) => {
        state.user = {...action.payload} as User;
        state.status = Status.SUCCESS;
      });
    builder.addCase(registrationUser.rejected, (state, action) => {
        state.status = Status.ERROR;
        state.user = null;
      });
  
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
