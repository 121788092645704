import $api from "../http";
import {AxiosResponse} from 'axios';
import {IUser} from "../models/User";

export default class UserService {
    static fetchUsers(): Promise<AxiosResponse<IUser[]>> {
        return $api.get<IUser[]>('/users')
    }

    static checkLogin(): Promise<AxiosResponse<IUser>> {
        return $api.get<IUser>('/checklogin')
    }
}

