import { createSlice } from '@reduxjs/toolkit';
import {currencyFetch} from './asyncActions';
import { Currency } from './types';

const initialState: Currency = {
  name: '',
  namesys: '',
  nameshort: ''  
};

const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder.addCase(currencyFetch.pending, (state, action) => {
      state.name = '';
      state.namesys = '';
      state.nameshort = '';
    });
    builder.addCase(currencyFetch.fulfilled, (state, action) => {
      state.name = action.payload.name;
      state.namesys = action.payload.namesys;
      state.nameshort = action.payload.nameshort;      
    });
    builder.addCase(currencyFetch.rejected, (state, action) => {
      state.name = '';
      state.namesys = '';
      state.nameshort = '';
    });   
  },
});

export default currencySlice.reducer;
