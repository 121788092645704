export const droplinks = [
  {
    label: "Параметры аккаунта",
    link: "userprofile",
    key: "0",
  },
  {
    label: "Мои заказы",
    link: "invoice",
    key: "1",
  },
  {
    label: "Мои юр. лица",
    link: "organization",
    key: "2",
  },
  // {
  //   label: "Мои лицензии",
  //   link: "license",
  //   key: "3",
  // },
];
