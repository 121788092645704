import React, { useState } from "react";
import { cartAdd, cartDec, cartDel } from "../../redux/cart/asyncActions";
import { selectCurrency } from "../../redux/currency/selectors";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import nomenclatureNoImg from "../../assets/img/nomenclature_no_image.png";

import config from "../../config";
import { Link } from "react-router-dom";

import s from "./Cart.module.scss";
import { Button, Col, Input, Modal, Row } from "antd";
import { CloseOutlined, MinusOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import ButtonGroup from "antd/es/button/button-group";

type CartProps = {
  nomenclatureid: string;
  name: string;
  price: number;
  cnt: number;
  images: any[];
};

const CartItem: React.FC<CartProps> = ({ nomenclatureid, name, price, cnt, images }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const currency = useAppSelector(selectCurrency);

  const onClickPlus = () => {
    dispatch(cartAdd(nomenclatureid));
  };

  const onClickMinus = () => {
    dispatch(cartDec(nomenclatureid));
  };

  const onAcceptDeleteProduct = () => {
    dispatch(cartDel(nomenclatureid));
    setModalOpen(false);
  };

  return (
    <>
      <Row align="middle" justify="space-between" style={{ marginBottom: "50px" }}>
        <Col xs={{ span: 10 }} sm={{ span: 8 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{ textAlign: "center" }}>
          <Link to={`/nomenclature/${nomenclatureid}`}>
            <img width={100} src={images.length > 0 ? `${config.API_URL}/nomenclature_image/` + images[0].imageid : nomenclatureNoImg} alt={name} />
          </Link>
        </Col>
        <Col xs={{ span: 14 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }} style={{ textAlign: "start" }}>
          <span className={s.item_name}>{name}</span>
        </Col>
        <Col xs={{ span: 10 }} sm={{ span: 8 }} md={{ span: 4 }} lg={{ span: 4 }} style={{ textAlign: "center" }}>
          <ButtonGroup>
            <Button disabled={cnt === 1} onClick={onClickMinus} icon={<MinusOutlined />} />
            <Input disabled value={cnt} style={{ width: "35px", textAlign: "center", borderRadius: "0", border: "0 ", color: "#1e266d" }} />
            <Button onClick={onClickPlus} icon={<PlusOutlined />} />
          </ButtonGroup>
        </Col>
        <Col xs={{ span: 10 }} sm={{ span: 8 }} md={{ span: 4 }} lg={{ span: 4 }} style={{ textAlign: "end" }}>
          <b>
            {(price * cnt).toLocaleString()} {currency.nameshort}
          </b>
        </Col>
        <Col xs={{ span: 4 }} sm={{ span: 8 }} md={{ span: 4 }} lg={{ span: 4 }} style={{ textAlign: "end" }}>
          <Button className={s.clear_item} onClick={() => setModalOpen(true)} icon={<CloseOutlined style={{}} />} style={{}}></Button>
        </Col>
      </Row>
      <Modal
        title={
          <div style={{ display: "flex" }}>
            <QuestionCircleOutlined style={{ fontSize: "40px", color: "red" }} />
            <h3 style={{ marginLeft: "30px" }}>Удалить "{name}" из корзины?</h3>
          </div>
        }
        centered
        open={modalOpen}
        onOk={onAcceptDeleteProduct}
        onCancel={() => setModalOpen(false)}
        okText="Удалить"
        cancelText="Отмена"
      />
    </>
  );
};

export default CartItem;
