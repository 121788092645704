import { createAsyncThunk } from '@reduxjs/toolkit';
import { Currency } from './types';
import currencyService from '../../services/CurrencyService';

export const currencyFetch = createAsyncThunk<Currency>(
  'currency/currencyFetch',
  async () => {
    const res = await currencyService.fetchCurrency();
    return res.data;
  },
);
