import React from "react";

import NavBar from "./NavBar/NavBar";
import { Hat } from "./Hat/Hat";

import s from "./Header.module.scss";

export const HeaderBlock: React.FC = () => {
  return (
    <div className={s.header}>
      <Hat />
      <NavBar />
    </div>
  );
};
