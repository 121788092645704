import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import nomenclatureNoImg from "../../assets/img/nomenclature_no_image.png";
import NomenclatureService, { Nomenclature, Characteristic } from "../../services/NomenclatureService";
import config from "../../config";
import { selectCurrency } from "../../redux/currency/selectors";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { selectUser } from "../../redux/user/selectors";
import { selectCartItemById } from "../../redux/cart/selectors";
import { cartAdd } from "../../redux/cart/asyncActions";
import LoginModal from "../../components/LoginModal";

import s from "./Nomenclature.module.scss";
import Loader from "@/components/Loader/Loader";
import { Breadcrumb, Button, Carousel, Col, Row, Table } from "antd";
import RegistrationModal from "@/components/RegistrationModal";

const NomenclaturePage: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [nomenclature, setNomenclature] = React.useState<Nomenclature | null>(null);
  const dispatch = useAppDispatch();
  const currency = useAppSelector(selectCurrency);
  const [isLoginShow, setIsLoginShow] = useState(false);
  const [isRegShow, setIsRegShow] = useState(false);
  const { user } = useAppSelector(selectUser);
  const onClickAdd = () => {
    dispatch(cartAdd(id));
  };
  const onLoginClick = () => {
    setIsLoginShow(!isLoginShow);
  };
  let { id }: { id?: any } = useParams();
  let addedCount = 0;
  const cartItem = useAppSelector(selectCartItemById(id));
  if (user) {
    addedCount = cartItem?.cnt || 0;
  }
  const columns = [
    {
      key: 1,
      title: "Параметры лицензии",
      dataIndex: "params",
    },
    {
      key: 2,
      title: "Значение",
      dataIndex: "value",
    },
  ];

  React.useEffect(() => {
    setLoading(true);
    NomenclatureService.getOne(id)
      .then((res) => {
        if (Object.keys(res.data).length === 0) {
          setError("product not found");
        } else {
          setNomenclature(res.data);
        }
      })
      .catch((e) => {
        // console.log('catch Nomenclature');
        if (e.response) {
          console.log(e.response.data);
          setError(e.response.data);
        } else {
          console.log("Error", e.message);
          setError(e.message);
        }
      })
      .finally(() => {
        // console.log('finally Nomenclature');
        setLoading(false);
      });
  }, [id]);

  function nomenclatureDescription() {
    if (nomenclature && nomenclature.description) return new TextDecoder("utf-8").decode(new Uint8Array(nomenclature.description.data));
    return "";
  }

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <div>{error}</div>;
  }
  if (nomenclature)
    return (
      <Row className={s.nomenclature} justify="center">
        <Col xs={{ span: 22 }} sm={{ span: 22 }} md={{ span: 22 }} lg={{ span: 18 }} xl={{ span: 18 }}>
          <br />
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/shop">Магазин</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Номенклатура</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col>
              <h1>{nomenclature.name}</h1>
              <p>{nomenclature.namefull}</p>
            </Col>
          </Row>
          {/* <hr className="text-dark" /> */}
          <Row justify="space-between">
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10 }} xl={{ span: 10 }} style={{ textAlign: "center" }}>
              {nomenclature.images.length === 0 && <img src={nomenclatureNoImg} alt={nomenclature.name} />}
              {nomenclature.images.length === 1 && <img width={200} src={`${config.API_URL}/nomenclature_image/` + nomenclature.images[0].imageid} alt={nomenclature.images[0].name} />}
              {nomenclature.images.length > 1 && (
                <Carousel effect="fade">
                  {nomenclature.images.map((item) => (
                    <img width={100} src={`${config.API_URL}/nomenclature_image/` + item.imageid} alt={item.name} key={item.imageid} />
                  ))}
                </Carousel>
              )}
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
              {nomenclature.price && (
                <Row
                  align="middle"
                  justify="space-between"
                  style={{
                    backgroundColor: "#1e266d",
                    color: "white",
                    padding: "10px 30px",
                  }}
                >
                  {!user && (
                    <Col>
                      <Button onClick={onLoginClick}>В корзину</Button>
                    </Col>
                  )}
                  {user && (
                    <Col>
                      {addedCount > 0 && (
                        <Button disabled style={{ color: "white" }}>
                          В корзине
                        </Button>
                      )}
                      {addedCount === 0 && <Button onClick={onClickAdd}>В корзину</Button>}
                    </Col>
                  )}
                  <Col>
                    <p>
                      Цена:{" "}
                      <b>
                        {nomenclature.price.toLocaleString()} {currency.nameshort}
                      </b>
                    </p>
                  </Col>
                </Row>
              )}
              <Row>
                {nomenclature.characteristics.length > 0 && (
                  <Table
                    columns={columns}
                    dataSource={nomenclature.characteristics.map((item: Characteristic, i) => ({ key: i, params: item.characteristicname, value: item.value1 }))}
                    pagination={false}
                    style={{ width: "100%" }}
                  />
                )}
              </Row>
            </Col>
          </Row>
          <div className={s.nomenclature_description}>
            <h2>Описание:</h2>
            <p></p>
            <div dangerouslySetInnerHTML={{ __html: nomenclatureDescription() }} style={{ fontSize: "20px" }}></div>
          </div>
        </Col>
        {isLoginShow && (
          <LoginModal
            registrationClick={() => {
              setIsLoginShow(false);
              setIsRegShow(true);
            }}
            handleClose={() => {
              setIsLoginShow(false);
            }}
          />
        )}
        {isRegShow && (
          <RegistrationModal
            LoginClick={() => {
              setIsLoginShow(true);
              setIsRegShow(false);
            }}
            handleClose={() => {
              setIsRegShow(false);
            }}
          />
        )}
      </Row>
    );
  return <div className="text-center">Нет данных</div>;
};

export default NomenclaturePage;
