import { createSlice } from '@reduxjs/toolkit';
import { Cart, CartStatus, CartSliceState } from './types';
import {cartFetch, cartAdd, cartDec, cartDel, cartClear} from './asyncActions';

const initialState: CartSliceState = {
  totalPrice: 0,
  items: [],
  status: CartStatus.LOADING, // loading | success | error
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder.addCase(cartFetch.pending, (state, action) => {
      state.status = CartStatus.LOADING;
      state.items = [];
      state.totalPrice = 0;
    });

    builder.addCase(cartFetch.fulfilled, (state, action) => {
      state.items = action.payload;
      state.status = CartStatus.SUCCESS;
      state.totalPrice = state.items.reduce((sum: number, item: Cart) => sum + item.cnt * item.price, 0);

    });

    builder.addCase(cartFetch.rejected, (state, action) => {
      state.status = CartStatus.ERROR;
      state.items = [];
    });

    builder.addCase(cartAdd.fulfilled, (state, action) => {
      const cartItem = state.items.find(item=>item.nomenclatureid === action.payload.nomenclatureid);
      if (cartItem) cartItem.cnt++;
      else state.items.push(action.payload);
      state.totalPrice = state.items.reduce((sum: number, item: Cart) => sum + item.cnt * item.price, 0);
      state.status = CartStatus.SUCCESS;
    });


    builder.addCase(cartDec.fulfilled, (state, action) => {
      const cartItem = state.items.find(item=>item.nomenclatureid === action.payload.nomenclatureid);
      if (cartItem) {
        cartItem.cnt--;
        if (cartItem.cnt === 0)
        {
          state.items = state.items.filter((item) => item.nomenclatureid !== cartItem.nomenclatureid);
        }
      }
      state.totalPrice = state.items.reduce((sum: number, item: Cart) => sum + item.cnt * item.price, 0);
      state.status = CartStatus.SUCCESS;
    });

    builder.addCase(cartDel.fulfilled, (state, action) => {
      state.items = state.items.filter(item=>item.nomenclatureid !== action.payload.nomenclatureid);
      state.totalPrice = state.items.reduce((sum: number, item: Cart) => sum + item.cnt * item.price, 0);
      state.status = CartStatus.SUCCESS;
    });    

    builder.addCase(cartClear.fulfilled, (state, action) => {
      state.items = [];
      state.totalPrice = 0;
      state.status = CartStatus.SUCCESS;
    });    

  },
});

export default cartSlice.reducer;
