import FooterBlock from "@/components/Footer/FooterBlock";
import HeaderBlock from "@/components/Header";
import { Content, Footer, Header } from "antd/es/layout/layout";
import React from "react";
import { Outlet } from "react-router-dom";

const MainLayout: React.FC = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Header style={{ flex: "0 0 auto" }}>
        <HeaderBlock />
      </Header>
      <Content style={{ flex: "1 0 auto" }}>
        <Outlet />
      </Content>
      <Footer style={{ flex: "0 0 auto" }}>
        <FooterBlock />
      </Footer>
    </div>
  );
};

export default MainLayout;
