import $api from "../http";
import {AxiosResponse} from 'axios';

export default class UserProfileService {
    static peopleGet(): Promise<AxiosResponse<any>> {
        return $api.get<any>('/userprofile/people')
    }

    static peopleUpdate({surname, name, middlename, phone}: any): Promise<AxiosResponse<any>> {
        return $api.post<any>('/userprofile/people', { surname, name, middlename, phone })
    }

    static emailUpdate({email}: any): Promise<AxiosResponse<any>> {
        return $api.post<any>('/userprofile/email', { email })
    }

    static passwdUpdate({password_old, password_new}: any): Promise<AxiosResponse<any>> {
        return $api.post<any>('/userprofile/passwd', { password_old, password_new })
    }

    static delete(): Promise<AxiosResponse<any>> {
        return $api.post<any>('/userprofile/delete')
    }
}

