import { Row, Col, Button, Form, Input, Card } from "antd";
import React from "react";
import ShopService, { Organization } from "../../services/ShopService";

import s from "./Organization.module.scss";

export enum OrganizationControlState {
  add,
  edit,
}

export type OrganizationControlProps = {
  state: OrganizationControlState;
  organization: Organization;
  saveCallback: (organization: Organization) => void;
  cancelCallback: () => void;
};

const OrganizationControl: React.FC<OrganizationControlProps> = ({ state, organization, saveCallback, cancelCallback }) => {
  const [candidat, setCandidat] = React.useState(organization);

  const saveOrganization = () => {
    if (state === OrganizationControlState.add) {
      insertOrganization();
    } else {
      updateOrganization();
    }
  };
  const insertOrganization = () => {
    ShopService.organizationInsert(candidat)
      .then((res) => {
        saveCallback(res.data);
      })
      .catch((e: any) => {
        if (e.response) {
          console.error(e.response.data);
        } else {
          console.error("Error", e.message);
        }
      });
  };
  const updateOrganization = () => {
    ShopService.organizationUpdate(candidat)
      .then((res) => {
        saveCallback(res.data);
      })
      .catch((e: any) => {
        if (e.response) {
          console.error(e.response.data);
        } else {
          console.error("Error", e.message);
        }
      });
  };
  return (
    <Card>
      <h2 style={{ margin: "0 0 30px 0" }}>{state === OrganizationControlState.add ? "Добавление" : "Редактирование"} юр. лица</h2>
      <Row>
        <Col span={24} offset={0}>
          <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} autoComplete="off">
            <Form.Item label="Наименование" name="name">
              <Input value={candidat.name} onChange={(e) => setCandidat({ ...candidat, name: e.target.value })} />
            </Form.Item>
            <Form.Item label="Полное наименование" name="fullname">
              <Input value={candidat.namefull} onChange={(e) => setCandidat({ ...candidat, namefull: e.target.value })} />
            </Form.Item>
            <Form.Item label="ИНН" name="inn">
              <Input value={candidat.inn} onChange={(e) => setCandidat({ ...candidat, inn: e.target.value })} />
            </Form.Item>
            <Form.Item label="КПП" name="kpp">
              <Input value={candidat.kpp} onChange={(e) => setCandidat({ ...candidat, kpp: e.target.value })} />
            </Form.Item>

            <Row>
              <Col span={11}>
                <Button className={s.save} type="primary" block onClick={saveOrganization}>
                  Сохранить
                </Button>
              </Col>
              <Col span={11} offset={2}>
                <Button className={s.cancel} block onClick={cancelCallback}>
                  Отмена
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Card>
  );
};

export default OrganizationControl;
