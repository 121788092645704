import React from "react";

import s from "./Loader.module.scss";
import { Col, Row } from "antd";

const Loader: React.FC = () => {
  return (
    <Row justify="center" className={s.loader}>
      <div></div>
      <div></div>
    </Row>
  );
};

export default Loader;
