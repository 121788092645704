import { Col, Row } from "antd";

import s from "./Footer.module.scss";

function Footer() {
  return (
    <Row className={s.footer} justify="center">
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 20 }} lg={{ span: 20 }} xl={{ span: 16 }}>
        <Row justify="space-between">
          <Col>
            <span>© 2023 КОМПАНИЯ PAUT.RU ВСЕ ПРАВА ЗАЩИЩЕНЫ.</span>
          </Col>
          <Col>
            <a href="tel: +7(391)2030015"> +7(391)203-00-15</a>
            <a href="mailto: support@neg24.ru" style={{ textTransform: "uppercase", marginLeft: "20px" }}>
              {" "}
              support@ldap.ru
            </a>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
export default Footer;
